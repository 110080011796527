<template>
  <div id="activeUser">
    <LoginFormComponent
      :formType="isExpiredUser==true? 'LINK_EXPIRED_USER' : 'ACTIVE_USER'"
      :formTitle="isExpiredUser==true ? $t('ACTIVE_USER_PAGE_FORM_TITLE_LINK_EXPIRED') : $t('ACTIVE_USER_PAGE_FORM_TITLE_ACTIVE_USER')"
      :btnLabel="isExpiredUser==true? '' : $t('ACTIVE_USER_PAGE_BUTTON_LABEL')"
      :label1="isExpiredUser==true? '' : $t('ACTIVE_USER_PAGE_LABEL_1')"
      :text6="$t('ACTIVE_USER_PAGE_TEXT_6')"
      :label2="isExpiredUser==true? '' : $t('ACTIVE_USER_PAGE_LABEL_2')"
      @update="update"
      :rulesRequired="true"
      :rulesMinPassword="true"
      :rulesConfirmed="true"
    />
 </div>
</template>

<script>
import LoginFormComponent from "../../components/LoginFormComponent";
import endpoints from "../../constants/endpoints";
import responseHelper from '@/utils/response-helper';
export default {
  name: "ActiveUser",
  data(){
    return {
      token:'',
      isExpiredUser: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    next(async vm => {
      let token = to.query.token;
      try {
        let res = await vm.$http.get(endpoints.checkToken(token));
        if (res.data) {
          let tokenStatus = res.data.token_sts;
          let userStatus = res.data.user_sts;
          if (tokenStatus == "VALID" && userStatus == "UNACTIVATED") {
            next();
          } else {
            // vm.$notify({
            //   group: "notifications",
            //   type: "error",
            //   title: "Error",
            //   text: `TOKEN_${tokenStatus}`
            // });
            // next("/login");
          }
        }
      } catch (err) {
        next("/login");
        vm.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("ACTIVE_USER_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err)
        });
      }
    });
  },
  async mounted(){
    let token=this.$route.query.token;
    this.token=token;
    const resultTokens = await this.$http.get(endpoints.checkToken(this.token));
    if(resultTokens.data.token_sts!=="VALID"){
      this.isExpiredUser=true;
    }
  },
  components: { LoginFormComponent },
  methods: {
    async update({ password }) {
      try {
        let token =
          this.token.slice(-1) == "." ? this.token.slice(0, -1) : this.token;

        const params = {
          verify_token: token,
          password: password
        };
        const result = await this.$http.post(endpoints.activeUser, params);
        if (result) {
          this.$notify({
            group: "notifications",
            type: "success",
            title: this.$t("ACTIVE_USER_PAGE_TITLE_NOTIFY_SUCCESS"),
            text: this.$t("ACTIVE_USER_PAGE_TEXT_NOTIFY_SUCCESS")
          });
          this.toLogin();
        }
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("ACTIVE_USER_PAGE_TITLE_NOTIFY_ACTIVE"),
          text: responseHelper.getErrorMessage(err)
        });
      }
    },
    toLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss">
#activeUser{
  .custom-card-body{
    .form-group{
      &.input--w{
        label{
          width: 145px;
          text-align: right;
        }
        input{
          width: calc(100% - 145px);
          @media(max-width: 1024px){
            width: 70%;
          }
          @media(max-width: 767px){
            width: calc(100% - 145px);
          }
        }
      }
      &.input2{
        .input-group{
          width: calc(100% - 145px);
          @media(max-width: 1024px){
            width: 70%;
          }
          @media(max-width: 767px){
            width: calc(100% - 145px);
          }
        }
        label{
          width: 145px;
          text-align: right;
        }
      }
    }
  }
  .error-msg-wrap{
    display: flex;
    justify-content: flex-end;
    .error-msg{
      width: calc(100% - 145px);
      padding: 0;
      @media(max-width: 1024px){
        width: 70%;
      }
      @media(max-width: 767px){
        width: calc(100% - 145px);
      }
    }
  }
}
</style>
